<template>
  <div class="leaderboard-page">
    <div class="leaderboard-section large">
      <div class="leaderboard-title">Active Point</div>
      <div class="leaderboard-content">
        <el-table :data="activepoint" height="400" stripe>
          <el-table-column prop="uname" label="Username" />
          <el-table-column prop="points" label="Points" />
          <el-table-column v-if="userLevel === 2 || userLevel === 3" label="Manage">
            <template #default="scope">
              <el-button type="primary" @click="openManageDialog(scope.row)">Manage</el-button>
            </template>
            </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="leaderboard-section">
      <div class="leaderboard-title">Overall Point</div>
      <div class="leaderboard-content">
        <el-table :data="pointall" height="400" stripe>
          <el-table-column prop="uname" label="Username" />
          <el-table-column prop="points" label="Points" />
        </el-table>
      </div>
    </div>

    <div class="leaderboard-section">
      <div class="leaderboard-title">Monthly Point</div>
      <div class="leaderboard-content">
        <el-table :data="pointmonth" height="400" stripe>
          <el-table-column prop="uname" label="Username" />
          <el-table-column prop="points" label="Points" />
        </el-table>
      </div>
    </div>

    <!-- 使用 v-model 替代 v-model:visible -->
    <el-dialog title="Manage Points" v-model="manageDialogVisible">
      <div>
        <p>Username: {{ currentUser.uname }}</p>
        <div class="points-control">
          <el-button @click="changePoints(-1)">-</el-button>
          <el-input v-model="currentUserPoints" style="width: 60px; text-align: center;"></el-input>
          <el-button @click="changePoints(1)">+</el-button>
        </div>
      </div>
      <template #footer>
        <el-button @click="manageDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="confirmUpdatePoints">Confirm</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { post } from "@/api/index";

export default {
  name: "LeaderboardPage",
  setup() {
    const activepoint = ref([
      { uname: "User1", points: 120 },
      { uname: "User2", points: 110 },
      { uname: "User3", points: 100 },
    ]);

    const pointall = ref([
      { uname: "User1", points: 300 },
      { uname: "User2", points: 290 },
      { uname: "User3", points: 250 },
    ]);

    const pointmonth = ref([
      { uname: "User1", points: 80 },
      { uname: "User2", points: 75 },
      { uname: "User3", points: 60 },
    ]);

    const userLevel = ref(0);
    const userId = ref(0);
    const userName = ref('');
    const router = useRouter();
    const manageDialogVisible = ref(false);
    const currentUser = ref({});
    const currentUserPoints = ref(0);

    onMounted(() => {
      if (sessionStorage.getItem("token")) {
        post(`/user/logged`).then(res => {
          if (res.res == 1) {
            userLevel.value = res.user.admin;
            userId.value = res.user.uid;
            userName.value = res.user.name;
          } else {
            sessionStorage.removeItem("token");
            sessionStorage.removeItem('Authorization');
          }
        });
      }
    });

    function openManageDialog(user) {
      currentUser.value = user;
      currentUserPoints.value = user.points;
      manageDialogVisible.value = true;
    }

    function changePoints(amount) {
      currentUserPoints.value += amount;
      if (currentUserPoints.value < 0) {
        currentUserPoints.value = 0;
      }
    }

    function confirmUpdatePoints() {
      currentUser.value.points = currentUserPoints.value;
      manageDialogVisible.value = false;
    }

    return {
      activepoint,
      pointall,
      pointmonth,
      userLevel,
      manageDialogVisible,
      openManageDialog,
      changePoints,
      confirmUpdatePoints,
      currentUser,
      currentUserPoints
    };
  },
};
</script>

<style scoped>
.leaderboard-page {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  padding: 20px;
}

.leaderboard-section {
  border: 1px solid #dadada;
  border-radius: 8px;
  padding: 16px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.leaderboard-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
  color: #111880;
}

.leaderboard-content {
  font-size: 14px;
  color: #333;
}

.el-table {
  margin-top: 20px;
}

.points-control {
  display: flex;
  justify-content: center;
  align-items: center;
}

.points-control .el-button {
  margin: 0 5px;
}
</style>
